import React, { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { Link } from "../components/link";

const AcquistaStep1 = ({ data, dispatch }) => {
  const partecipantiRef = useRef();

  const partecipantiOption = [
    { value: 1, label: "Singolo partecipante" },
    { value: 2, label: "2 Partecipanti" },
    { value: 3, label: "3 Partecipanti" },
    { value: 4, label: "4 Partecipanti" },
    { value: 5, label: "5 Partecipanti" },
    { value: 6, label: "6 Partecipanti" },
    { value: 7, label: "7 Partecipanti" },
    { value: 8, label: "8 Partecipanti" },
  ];

  const {
    register,
    handleSubmit,
    control: controlPartecipanti,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...data,
      customerType: data?.customerType || "azienda",
    },
    mode: "onChange",
  });

  const onSubmit = (data) => {
    console.log("data: ", data);
    dispatch({
      type: "step1",
      payload: data,
    });
  };

  const customerType = watch("customerType");
  const isAzienda = customerType === "azienda";

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="box-progress">
            <div className="box-progress__title">Inserisci i tuoi dati</div>
            <div className="box-progress__bar">
              <span></span>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form pb-0">
        <div className="row">
          <div className="col-12">
            <div className="d-flex">
              <div>
                <label className="radio">
                  <div>Azienda/Partita IVA</div>
                  <input
                    type="radio"
                    name="customerType"
                    value="azienda"
                    className="radio__input"
                    {...register("customerType", { required: true })}
                  />
                  <span className="radio__mark"></span>
                </label>
              </div>
              <div>
                <label className="radio">
                  <div>Privato</div>
                  <input
                    type="radio"
                    name="customerType"
                    value="privato"
                    className="radio__input"
                    {...register("customerType", { required: true })}
                  />
                  <span className="radio__mark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12">
            <div className="form__group">
              <label className="checkbox">
                <div>Socio HRC</div>
                <input
                  type="checkbox"
                  name="sociohrc"
                  id="sociohrc"
                  className="checkbox__input"
                  {...register("sociohrc")}
                />
                <span className="checkbox__mark"></span>
              </label>
              {errors.sociohrc ? (
                <span className="d-block form__error">{errors.sociohrc.message}</span>
              ) : null}
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="name"
                  id="name"
                  placeholder=" "
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Specificare un nome.",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: "Specificare un nome (solo caratteri e/o spazi).",
                    },
                  })}
                />
                <label className="form__label" htmlFor="name">
                  Nome *
                </label>
              </div>
              {errors.name ? (
                <span className="d-block form__error">{errors.name.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="surname"
                  id="surname"
                  placeholder=" "
                  {...register("surname", {
                    required: {
                      value: true,
                      message: "Specificare un cognome.",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: "Specificare un cognome (solo caratteri e/o spazi).",
                    },
                  })}
                />
                <label className="form__label" htmlFor="surname">
                  Cognome *
                </label>
              </div>
              {errors.surname ? (
                <span className="d-block form__error">{errors.surname.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="company"
                  id="company"
                  placeholder=" "
                  {...register("company", {
                    required: {
                      value: isAzienda,
                      message: "Specificare l'azienda.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="company">
                  Azienda {isAzienda ? "*" : ""}
                </label>
              </div>
              {errors.company ? (
                <span className="d-block form__error">{errors.company.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="role"
                  id="role"
                  placeholder=" "
                  {...register("role", {
                    required: {
                      value: isAzienda,
                      message: "Specificare il ruolo in azienda.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="role">
                  Ruolo in azienda {isAzienda ? "*" : ""}
                </label>
              </div>
              {errors.role ? (
                <span className="d-block form__error">{errors.role.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="email"
                  className="form__input-text"
                  name="email"
                  id="email"
                  placeholder=" "
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Specificare un indirizzo email.",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Specificare un indirizzo email valido.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="email">
                  Email *
                </label>
              </div>
              {errors.email ? (
                <span className="d-block form__error">{errors.email.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__placeholder-select">Numero di partecipanti *</div>
              <Controller
                render={({ field: { value, onChange, ...other } }) => (
                  <Select
                    {...other}
                    options={partecipantiOption}
                    isSearchable={false}
                    onChange={(option) => onChange(option.value)}
                    className="form__select"
                    classNamePrefix="select"
                    placeholder="Seleziona"
                    noOptionsMessage={() => "Nessun risultato"}
                    value={partecipantiOption.find((option) => option.value === value)}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#1a1b1f",
                        primary: "#333333",
                      },
                    })}
                    ref={(ref) => (partecipantiRef.current = ref)}
                  />
                )}
                name="partecipanti"
                control={controlPartecipanti}
                onFocus={() => partecipantiRef.current.focus()}
                rules={{ required: true }}
              />
              {errors.partecipanti ? (
                <span className="d-block form__error">Selezionare il numero di partecipanti.</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form__navigation">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <Link to="/ticket/" className="btn-link btn-link--small btn-link--prev">
                  <span className="btn-link__text">Back</span>
                  <span className="btn-link__circle"></span>
                </Link>
                <button type="submit" className="btn-link btn-link--small btn-link--next">
                  <span className="btn-link__circle"></span>
                  <span className="btn-link__text">Prosegui</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AcquistaStep1;
