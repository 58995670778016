const PRICES = {
  "day1": 37500, // 19
  "day2": 37500, // 20
  "day1-2": 37500, // 19
};

const TICKET_LABELS = {
  it: {
    "day1": "Ingresso 19 giugno",
    "day2": "Ingresso 20 giugno",
    "day1-2": "Ingresso 19 giugno",
  },
};

// const DISCOUNTS = {
//   // Se cambiano gli sconti controllare le label in step4 + calcolo prezzo
//   partner: 0.2,
//   multiple: 0.2,
//   sociohrc: 0.2,
//   coupon: {
//     1: 0.25,
//     2: 0.5,
//     3: 0.3,
//   },
//   specialCoupon: {
//     1: 0.05,
//     2: 0.1,
//   },
// };

const IVA = 0.22;

const NAMES = TICKET_LABELS.it;
export const names = NAMES;

export const personPrice = ({ id, partner }, length, options = {}, skipDisconts) => {
  const { iva, coupon, specialCoupon, sociohrc } = options;
  let price = 0;
  if (id && PRICES[id]) {
    price = PRICES[id];
    // if (!skipDisconts) {
    //   let discount = 0;
    //   if (coupon) {
    //     // Sconto coupon
    //     discount = DISCOUNTS["coupon"][coupon];
    //   } else if (sociohrc && length > 1) {
    //     // Sconto per socio HRC + multiplo
    //     discount = DISCOUNTS["sociohrc"];
    //   } else if (sociohrc) {
    //     // Sconto per socio HRC
    //     discount = DISCOUNTS["sociohrc"];
    //   } else if (length > 1) {
    //     // Sconto per più di un biglietto
    //     discount = DISCOUNTS["multiple"];
    //   }
    //   if (partner) {
    //     // Sconto partner
    //     discount = DISCOUNTS["partner"];
    //   }
    //   if (specialCoupon && !coupon) {
    //     // Sconto coupon speciale
    //     discount += DISCOUNTS["specialCoupon"][specialCoupon];
    //   }
    //   price = Math.round(price * (1 - discount));
    // }
    if (iva) {
      price = Math.round(price * (1 + IVA));
    }
  }
  return price;
};

export const totalPrice = (people, options, skipDisconts) => {
  return people.reduce(
    (sum, person) => sum + personPrice(person, people.length, options, skipDisconts),
    0
  );
};

export const totalDetailed = ({ people, iva, coupon, specialCoupon, sociohrc }) => {
  let total = totalPrice(people, { iva: false, coupon, specialCoupon, sociohrc });
  let amountIva = 0;
  let discount = 0;
  if (iva === "true") {
    const totalWithIva = totalPrice(people, { iva: true, coupon, specialCoupon, sociohrc });
    amountIva = totalWithIva - total;
    total = totalWithIva;
  }
  const totalWithoutDiscont = totalPrice(people, { iva: false }, true);
  discount =
    totalWithoutDiscont - totalPrice(people, { iva: false, coupon, specialCoupon, sociohrc });
  return {
    amountIva,
    discount,
    total,
  };
};

export const PERSON_KEY = "person_";
export const EMAIL_KEY = " email: ";

export const getPersonName = (person, length) => {
  const name = NAMES[person.id];
  // Controllo che gli id dei biglietti siano validi
  if (!name) throw new Error("Illegal id parameter: " + person.id);
  // Costruisco il nome in base allo sconto
  // return person.partner || length > 1 ? `${name} (-20%)` : name;
  return name;
};
